import PATHS from "routes/const";

export const listSideBar = [
  {
    nameParent: "Soil Sampling",
    link: undefined,
    isHasChildren: true,
    isActive: false,
    isOpen: false,
    listChildren: [
      {
        name: "Sample Scheduling",
        link: PATHS.SAMPLE_SCHEDULING,
        isActive: false,
        isHasChildren: false,
        listChildren: [],
      },
      {
        name: "Sample Processing",
        link: undefined,
        isActive: false,
        isHasChildren: true,
        isOpen: false,
        listChildren: [
          {
            name: "Soil",
            link: PATHS.COC_LIST_SOIL,
            isActive: false,
            isHasChildren: false,
            listChildren: [],
          },
          // {
          //   name: "Liquid",
          //   link: PATHS.COC_LIST_LIQUID,
          //   isActive: false,
          //   isHasChildren: false,
          //   listChildren: [],
          // },
          // {
          //   name: "Gas",
          //   link: PATHS.COC_LIST_GAS,
          //   isActive: false,
          //   isHasChildren: false,
          //   listChildren: [],
          // },
        ],
      },
      {
        name: "Result Table (with CoC)",
        link: PATHS.RESULT_TABLE_COC,
        isActive: false,
        isHasChildren: false,
        listChildren: [],
      },
      {
        name: "Result Table (without CoC)",
        link: PATHS.RESULT_TABLE_NO_COC,
        isActive: false,
        isHasChildren: false,
        listChildren: [],
      },
      {
        name: "Generate Exceedance Map",
        link: PATHS.MAP,
        isActive: false,
        isHasChildren: false,
        listChildren: [],
      },
      {
        name: "Photo List",
        link: PATHS.PHOTO_LIST,
        isActive: false,
        isHasChildren: false,
        listChildren: [],
      },
      {
        name: "Field Observations",
        link: PATHS.FIELD_OBSERVATIONS,
        isActive: false,
        isHasChildren: false,
        listChildren: [],
      },
    ],
  },
  {
    nameParent: "Water Quality",
    link: undefined,
    isHasChildren: true,
    isActive: false,
    isOpen: false,
    listChildren: [
      {
        name: "Sample Scheduling",
        link: PATHS.WATER_QUALITY,
        isActive: false,
        isHasChildren: false,
        listChildren: [],
      },
      {
        name: "Sample Processing",
        link: PATHS.GENERATE_COC,
        isActive: false,
        isHasChildren: false,
        listChildren: [],
      },
      {
        name: "Field Sheet and Parameters",
        link: PATHS.FIELD_SHEET_AND_PARAMETERS,
        isActive: false,
        isHasChildren: false,
        listChildren: [],
      },
      {
        name: "Result Table (with CoC)",
        link: PATHS.WATER_QUALITY_COC,
        isActive: false,
        isHasChildren: false,
        listChildren: [],
      },
      {
        name: "Result Table (without CoC)",
        link: PATHS.WATER_QUALITY_WITHOUT_COC,
        isActive: false,
        isHasChildren: false,
        listChildren: [],
      },
      {
        name: "Photo List",
        link: PATHS.PHOTO_LIST_WQ,
        isActive: false,
        isHasChildren: false,
        listChildren: [],
      },
    ],
  },
  {
    nameParent: "Groundwater Level",
    link: undefined,
    isHasChildren: true,
    isOpen: false,
    isActive: false,
    listChildren: [
      {
        name: "Schedule Piezometers",
        link: PATHS.GROUNDWATER_LEVEL,
        isActive: false,
        isHasChildren: true,
        isOpen: false,
        listChildren: [
          {
            name: "Level Logger",
            link: PATHS.SCHEDULE_FIELD_WORK,
            isHasChildren: false,
            isActive: false,
            listChildren: [],
          },
          {
            name: "Dip Meter Reading",
            link: PATHS.MANUAL_DIP,
            isHasChildren: false,
            isActive: false,
            listChildren: [],
          },
        ],
      },
      {
        name: "Upload Data",
        link: PATHS.UPLOAD_DATA,
        isActive: false,
        isHasChildren: true,
        isOpen: false,
        listChildren: [
          {
            name: "Level Logger Data",
            link: PATHS.LEVEL_LOGGER_DATA,
            isHasChildren: false,
            isActive: false,
            listChildren: [],
          },
          {
            name: "Rainfall Data ",
            link: PATHS.RAIN_FALL,
            isHasChildren: false,
            isActive: false,
            listChildren: [],
          },
        ],
      },
      {
        name: "Summary Tables",
        link: PATHS.SUMMARY_TABLE,
        isActive: false,
        isHasChildren: true,
        isOpen: false,
        listChildren: [
          {
            name: "Level Logger",
            link: PATHS.RESULT_TABLE,
            isHasChildren: false,
            isActive: false,
            listChildren: [],
          },
          {
            name: "Dip Meter Reading",
            link: PATHS.RESULT_TABLE_MANUAL_DIP,
            isHasChildren: false,
            isActive: false,
            listChildren: [],
          },
        ],
      },
      {
        name: "Maps",
        link: PATHS.ALL_MAP,
        isHasChildren: true,
        isOpen: false,
        isActive: false,
        listChildren: [
          {
            name: "Groundwater Level",
            link: PATHS.GIS_MAP,
            isHasChildren: false,
            isActive: false,
            listChildren: [],
          },
        ],
      }
    ]
  },
  // {
  //   nameParent: "Landfill Gas Monitoring",
  //   link: PATHS.LANDFILL_GAS_MONITORING,
  //   isHasChildren: true,
  //   isActive: false,
  //   isOpen: false,
  //   listChildren: [
  //     {
  //       name: "Well",
  //       link: PATHS.WELL_LIST,
  //       isActive: false, 
  //       isHasChildren: true,
  //       isOpen: false,
  //       listChildren: [
  //         {
  //           name: "Well List",
  //           link: PATHS.WELL_LIST,
  //           isActive: false,
  //           isHasChildren: false,
  //           listChildren: [],
  //         },  
  //         {
  //           name: "Upload Well Data",
  //           link: PATHS.WELL_MANUAL_UPLOAD,
  //           isActive: false,
  //           isHasChildren: false,
  //           listChildren: [],
  //         },{
  //           name: "Well Photos",
  //           link: PATHS.WELL_PHOTO,
  //           isActive: false,
  //           isHasChildren: false,
  //           listChildren: [],
  //         },
  //       ],
  //     },
  //     // {
  //     //   name: "VI notes",
  //     //   link: PATHS.VI_NOTES,
  //     //   isActive: false,
  //     //   isHasChildren: false,
  //     //   listChildren: [],
  //     // },
  //     {
  //       name: "Visual Inspection",
  //       link: PATHS.VI_PHOTOS,
  //       isActive: false,
  //       isHasChildren: true,
  //       isOpen: false,
  //       listChildren: [
  //         {
  //           name: "Comments",
  //           link: PATHS.VI_COMMENT,
  //           isActive: false,
  //           isHasChildren: false,
  //           listChildren: [],
  //         },
  //         {
  //           name: "Photo List",
  //           link: PATHS.VI_PHOTOS,
  //           isActive: false,
  //           isHasChildren: false,
  //           listChildren: [],
  //         },
  //       ],
  //     }
  //   ]
  // }, 
];
